/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import Img from "gatsby-image"
import slugify from "slugify"

const view = `/view/`
const ViewCard = ({ data }) => {
  // View Image
  const hasImage = data.frontmatter.featuredImage.childImageSharp.sizes.src.includes(
    "spacer.png"
  )
    ? false
    : true
  const ViewImage = hasImage
    ? data.frontmatter.featuredImage.childImageSharp.fluid
    : ""
  const potfolioImg = (
    <Link to={view + slugify(`${data.frontmatter.title}`)}>
      <Img
        width="640"
        height="360"
        fluid={ViewImage}
        objectFit="cover"
        objectPosition="100% 100%"
        alt={data.frontmatter.title + " - Featured image"}
        sx={viewStyles.featuredImage}
      />
    </Link>
  )

  const ViewInfo = (
    <div sx={viewStyles.ViewInfo}>
      {data.frontmatter.title ? (
        <h2 sx={viewStyles.viewTitle}>
          <Link to={view + slugify(`${data.frontmatter.title}`)}>
            {data.frontmatter.title}
          </Link>
        </h2>
      ) : (
        ""
      )}
      <Link
        sx={{
          variant: "variants.button.primaryLink",
          fontSize: [2, 3],
          fontWeight: "heading",
          mr: 4,
        }}
        to={view + slugify(`${data.frontmatter.title}`)}
      >
        See More <span className="icon">&rarr;</span>
      </Link>
    </div>
  )

  return (
    <div>
      {data.frontmatter.align === "left" ? (
        <article sx={viewStyles.viewContainer}>
          {potfolioImg}
          {ViewInfo}
        </article>
      ) : (
        ""
      )}

      {data.frontmatter.align === "right" ? (
        <article sx={viewStyles.viewContainer}>
          <div sx={viewStyles.orderOne}>{ViewInfo}</div>
          <div sx={viewStyles.orderTwo}>{potfolioImg}</div>
        </article>
      ) : (
        ""
      )}

      {data.frontmatter.align === "center" ? (
        <article sx={viewStyles.viewContainerCenter}>
          {potfolioImg}
          {ViewInfo}
        </article>
      ) : (
        ""
      )}
    </div>
  )
}

export default ViewCard

const viewStyles = {
  viewContainer: {
    maxWidth: "100%",
    width: "100%",
    display: "grid",
    gridTemplateColumns: ["1fr", "1fr", "1fr  1fr"],
    justifyContent: "center",
    mb: [7, 7, 7, 8],
    gap: [4, 4, 4, 7],
  },
  orderOne: {
    order: ["2", "2", "1"],
  },
  orderTwo: {
    order: ["1", "1", "2"],
  },
  ViewInfo: {
    maxWidth: "75ch",
    pt: [0, 0, 5, 7],
  },
  featuredImage: {
    maxWidth: "100%",
    width: "100%",
    display: "block",
    m: "0",
    borderRadius: "12px",
    border: "1px solid",
    borderColor: "global.borderColor",
  },
  viewTitle: {
    mt: 0,
    mb: 4,
    a: {
      fontWeight: "heroHeading",
      fontSize: [4, 4, 5, 6, 7],
      color: "home.headingColor",
      "&:hover": {
        color: "global.primarylinkColor",
      },
    },
  },
  viewDecription: {
    fontSize: [3, 3, 3, 4],
    color: "global.textColor",
    mt: 0,
    mb: 5,
  },
  viewTag: {
    display: ["table", "flex"],
    span: {
      variant: "variants.tag",
    },
  },
  viewContainerCenter: {
    maxWidth: "100%",
    width: "100%",
    display: "grid",
    gridTemplateColumns: ["1fr"],
    textAlign: ["left", "left", "left"],
    mb: 7,
    gap: 4,
    ".gatsby-image-wrapper": {
      maxWidth: "100% !important",
      maxHeight: "100% !important",
    },
  },
}
